/*
 * @Description:
 * @Version: 2.0
 * @Autor: Seven
 * @Date: 2024-07-15 08:41:18
 * @LastEditors: Seven
 * @LastEditTime: 2024-07-23 10:17:20
 */
import { createApp } from 'vue';
import App from './App.vue';
import { setupVab } from '~/library';
import { setupI18n } from '@/i18n';
import { setupStore } from '@/store';
import '@/assets/css/index.scss';
import { setupRouter } from '@/router';
/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
import { baseURL, pwa } from './config';
import { isExternal } from '@/utils/validate';
import directives from '@/directives/index';
const app = createApp(App);
app.use(directives);
if (process.env.NODE_ENV === 'production' && !isExternal(baseURL)) {
    const { mockXHR } = require('@/utils/static');
    mockXHR();
}
/**
 * @description 生产环境启用组件初始化，编译，渲染和补丁性能跟踪。仅在开发模式和支持 Performance.mark API的浏览器中工作。
 */
//if (process.env.NODE_ENV === 'development') app.config.performance = true
if (pwa)
    require('./registerServiceWorker');
setupVab(app);
setupI18n(app);
setupStore(app);
setupRouter(app)
    .isReady()
    .then(() => app.mount('#app'));
