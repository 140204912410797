import request from '@/utils/request';
import { encryptedData } from '@/utils/encrypt';
import { loginRSA } from '@/config';
// export async function login(data: any) {
//   if (loginRSA) {
//     data = await encryptedData(data)
//   }
//   return request({
//     url: '/login',
//     method: 'post',
//     data,
//   })
// }
export function login(data) {
    return request({
        url: '/passport/login',
        method: 'post',
        data,
    });
}
export async function socialLogin(data) {
    if (loginRSA) {
        data = await encryptedData(data);
    }
    return request({
        url: '/socialLogin',
        method: 'post',
        data,
    });
}
export function getUserInfoAPI() {
    return request({
        url: '/account/getUserInfo',
        method: 'post',
    });
}
export function logout() {
    return request({
        url: '/logout',
        method: 'get',
    });
}
export function register(data) {
    return request({
        url: '/register',
        method: 'post',
        data,
    });
}
