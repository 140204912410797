import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-76a43974"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
  return _openBlock(), _createBlock(_component_el_breadcrumb, {
    class: "vab-breadcrumb",
    separator: ">"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.breadcrumbList, (item, index) => {
      return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
        key: index,
        to: $setup.handleTo(item.redirect)
      }, {
        default: _withCtx(() => [item.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
          key: 0,
          icon: item.meta.icon,
          "is-custom-svg": item.meta.isCustomSvg
        }, null, 8 /* PROPS */, ["icon", "is-custom-svg"])) : _createCommentVNode("v-if", true), item.meta.title ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($setup.translate(item.meta.title)), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  });
}