import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_column_bar = _resolveComponent("vab-column-bar");
  const _component_vab_nav = _resolveComponent("vab-nav");
  const _component_vab_tabs = _resolveComponent("vab-tabs");
  const _component_vab_app_main = _resolveComponent("vab-app-main");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vab-layout-column", {
      fixed: $props.fixedHeader,
      'no-tabs-bar': !$props.showTabs
    }])
  }, [_createVNode(_component_vab_column_bar), _createElementVNode("div", {
    class: _normalizeClass(["vab-main", {
      ['vab-main-' + $setup.theme.columnStyle]: true,
      'is-collapse-main': $props.collapse
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["vab-layout-header", {
      'fixed-header': $props.fixedHeader
    }])
  }, [_createVNode(_component_vab_nav), _withDirectives(_createVNode(_component_vab_tabs, null, null, 512 /* NEED_PATCH */), [[_vShow, $props.showTabs]])], 2 /* CLASS */), _createVNode(_component_vab_app_main)], 2 /* CLASS */)], 2 /* CLASS */);
}