/* 防止偶发性自动导入失败 */
import { computed, defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '@/store/modules/settings';
const imports = require.context('./components', true, /\.vue$/);
const Components = {};
imports.keys().forEach(key => {
  Components[key.replace(/(\/|\.|vue)/g, '')] = imports(key).default;
});
export default defineComponent({
  name: 'VabMenu',
  components: Components,
  props: {
    item: {
      type: Object,
      required: true
    },
    layout: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const settingsStore = useSettingsStore();
    const {
      collapse
    } = storeToRefs(settingsStore);
    const menuComponent = computed(() => props.item.children && props.item.children.some(_route => {
      return _route.meta.hidden !== true;
    }) ? 'VabSubMenu' : 'VabMenuItem');
    return {
      collapse,
      menuComponent
    };
  }
});