/**
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import { resetRouter } from '@/router';
import { asyncRoutes, constantRoutes } from '@/router/routes';
import { filterRoutes, filterBackRoutes, flappingFontRoutes, handleMenuDataSort } from '@/utils/routes';
import { authentication, rolesControl } from '@/config';
import { useUserStore } from '@/store/modules/user';
export const useRoutesStore = defineStore('routes', {
  state: () => ({
    /**
     * 一级菜单值
     */
    tab: {
      data: undefined
    },
    /**
     * 一级菜单
     */
    tabMenu: undefined,
    /**
     * 自定义激活菜单
     */
    activeMenu: {
      data: undefined
    },
    /**
     * 一级菜单
     */
    routes: [],
    /*
      按钮权限
    */
    btnPermissions: []
  }),
  getters: {
    getTab: state => state.tab,
    getTabMenu: state => state.tab.data ? state.routes.find(route => route.name === state.tab.data) : {
      meta: {
        title: ''
      },
      redirect: '404'
    },
    getActiveMenu: state => state.activeMenu,
    getRoutes: state => state.routes.filter(_route => _route.meta.hidden !== true),
    getPartialRoutes: state => state.routes.find(route => route.name === state.tab.data)?.children || []
  },
  actions: {
    setBtnPermissions(payload) {
      this.btnPermissions = payload;
    },
    clearRoutes() {
      this.routes = [];
      this.btnPermissions = [];
    },
    /**
     * @description 多模式设置路由
     * @param mode
     * @returns
     */
    async setRoutes(mode = 'none') {
      // 默认前端路由
      let routes = [...asyncRoutes];
      // 设置游客路由关闭路由拦截(不需要可以删除)
      const control = mode === 'visit' ? false : rolesControl;
      // 设置后端路由(不需要可以删除)
      if (authentication === 'all') {
        const {
          backRoutes
        } = useUserStore(); // 获取后端路由
        // console.log('backRoutes', backRoutes)
        // const {
        //   data: { list },
        // } = await getList()
        // if (!isArray(list))
        //   gp.$baseMessage(
        //     '路由格式返回有误！',
        //     'error',
        //     'vab-hey-message-error'
        //   )
        // if (list[list.length - 1].path !== '*')
        //   list.push({
        //     path: '/:pathMatch(.*)*',
        //     redirect: '/404',
        //     name: 'NotFound',
        //     meta: { hidden: true },
        //   })
        // routes = convertRouter(list)
        const flappingRoutes = flappingFontRoutes();
        const filterBackRoutesObj = filterBackRoutes(backRoutes, flappingRoutes);
        // console.log('后端路由', backRoutes)
        routes = filterBackRoutesObj.routes;
        this.setBtnPermissions(filterBackRoutesObj.btnPermissions);
        // console.log('按钮权限', filterBackRoutesObj.btnPermissions)
        routes = handleMenuDataSort(routes);
        // console.log('后端路由', backRoutes)
        // console.log('后端匹配好的路由', routes)
        // console.log('拍扁后的路由', flappingRoutes)
      }
      // 根据权限和rolesControl过滤路由
      const accessRoutes = filterRoutes([...constantRoutes, ...routes], control);
      // 设置菜单所需路由
      this.routes = JSON.parse(JSON.stringify(accessRoutes));
      // 根据可访问路由重置Vue Router
      await resetRouter(accessRoutes);
    },
    changeMenuMeta(options) {
      function handleRoutes(routes) {
        return routes.map(route => {
          if (route.name === options.name) Object.assign(route.meta, options.meta);
          if (route.children && route.children.length) route.children = handleRoutes(route.children);
          return route;
        });
      }
      this.routes = handleRoutes(this.routes);
    },
    /**
     * @description 修改 activeName
     * @param activeMenu 当前激活菜单
     */
    changeActiveMenu(activeMenu) {
      this.activeMenu.data = activeMenu;
    }
  }
});