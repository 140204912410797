import { debounce } from 'lodash-es';
const scrollDistance = 50; // 距离底部的距离
let elscrollbarDOM = null; // 监听滚动的DOM元素
let isBottom = false; // 是否触底
let userCallBack = null; // 用户的回调函数
const scrollFn = debounce(function () {
    // 只监听纵向滚动
    if (!this.scrollLeft && this.scrollTop) {
        const diff = this.scrollHeight - this.scrollTop - this.clientHeight;
        if (diff <= scrollDistance) {
            if (diff === 0 && isBottom) {
                // 决绝手动拖动滚动条一直触底时不处罚回调的问题
                isBottom = false;
            }
            if (!isBottom) {
                isBottom = true;
                userCallBack();
                // console.log('触底加载哦',  isBottom)
            }
        }
        else {
            isBottom = false;
            // console.log('没有触底', isBottom)
        }
    }
}, 60, {
    leading: true
});
const vTableScroll = {
    mounted(el, binding) {
        elscrollbarDOM = el.querySelector('.el-table .el-table__body-wrapper .el-scrollbar .el-scrollbar__wrap');
        if (elscrollbarDOM) {
            userCallBack = binding.value;
            elscrollbarDOM.addEventListener('scroll', scrollFn);
        }
    },
    unmounted() {
        if (elscrollbarDOM) {
            elscrollbarDOM.removeEventListener('scroll', scrollFn);
            elscrollbarDOM = null;
            userCallBack = null;
            isBottom = false;
        }
    }
};
export default vTableScroll;
