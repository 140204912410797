import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_menu = _resolveComponent("vab-menu");
  return !_ctx.item.meta.hidden ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.menuComponent), {
    key: 0,
    "item-or-menu": _ctx.item
  }, {
    default: _withCtx(() => [_ctx.item.children && _ctx.item.children.length ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList(_ctx.item.children, route => {
      return _openBlock(), _createBlock(_component_vab_menu, {
        key: route.path,
        item: route
      }, null, 8 /* PROPS */, ["item"]);
    }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["item-or-menu"])) : _createCommentVNode("v-if", true);
}