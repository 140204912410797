import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "vab-app-main flex flex-column h-full"
};
const _hoisted_2 = {
  class: "flex-1 overflow-hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_router_view = _resolveComponent("vab-router-view");
  const _component_vab_footer = _resolveComponent("vab-footer");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("section", _hoisted_2, [_createVNode(_component_vab_router_view)]), _createVNode(_component_vab_footer)]);
}