import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-19e042ab"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _component_el_autocomplete = _resolveComponent("el-autocomplete");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return $setup.theme.showSearch ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_vab_icon, {
    icon: "search-line",
    onClick: $setup.openDialog
  }), _createVNode(_component_el_dialog, {
    modelValue: $setup.state.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.state.dialogVisible = $event),
    width: '40%'
  }, {
    default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_autocomplete, {
      modelValue: $setup.state.queryForm.searchWord,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.state.queryForm.searchWord = $event),
      "fetch-suggestions": $setup.querySearchAsync,
      "select-when-unmatched": "",
      onSelect: $setup.handleSelect
    }, {
      prefix: _withCtx(() => [_createVNode(_component_vab_icon, {
        icon: "search-line"
      })]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])), [[$setup["vFocus"]]])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"])])) : _createCommentVNode("v-if", true);
}