import Layout from '@vab/layouts/index.vue';
export const constantRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/index.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/register/index.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
    {
        path: '/callback',
        name: 'Callback',
        component: () => import('@/views/callback/index.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/views/403.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/404.vue'),
        meta: {
            hidden: true,
            isStatic: true,
        },
    },
];
export const asyncRoutes = [
    {
        path: '/',
        name: 'Root',
        component: Layout,
        meta: {
            title: '租户申请',
            icon: 'home-2-line',
        },
        children: [
            // {
            //   path: 'index',
            //   name: 'Index',
            //   component: () => import('@/views/index/index.vue'),
            //   meta: {
            //     title: '自主申请',
            //     icon: 'home-2-line',
            //   },
            // },
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import('@/views/index/dashboard.vue'),
                meta: {
                    title: '联系专家顾问',
                    icon: 'dashboard-line',
                },
            },
            // {
            //   path: 'workbench',
            //   name: 'Workbench',
            //   component: () => import('@/views/index/workbench.vue'),
            //   meta: {
            //     title: '工作台',
            //     icon: 'settings-6-line',
            //     dot: true,
            //   },
            // },
            // {
            //   path: 'store',
            //   name: 'Store',
            //   component: () => import('@/views/index/store.vue'),
            //   meta: {
            //     title: '仓库',
            //     icon: 'app-store-line',
            //     dot: true,
            //   },
            // },
        ],
    },
    {
        path: '/manage',
        name: 'Manage',
        component: Layout,
        meta: {
            title: '租户管理',
            icon: 'code-box-line',
        },
        children: [
            {
                path: 'tenantBook',
                name: 'TenantBook',
                component: () => import('@/views/tenantManagement/tenantBook/index.vue'),
                meta: {
                    title: '账套',
                    icon: 'remixicon-line',
                    noKeepAlive: true,
                },
            },
            {
                path: 'tenantAccounts',
                name: 'TenantAccounts',
                component: () => import('@/views/tenantManagement/tenantAccounts/index.vue'),
                meta: {
                    title: '租户信息',
                    icon: 'contacts-line',
                    noKeepAlive: true,
                    // guard: ['TenantAccounts'],
                },
            },
        ],
    },
    // {
    //   path: '/ManagePush',
    //   name: 'ManagePush',
    //   component: Layout,
    //   meta: {
    //     title: '租户消息',
    //     icon: 'code-box-line',
    //   },
    //   children: [
    //     {
    //       path: 'TextManage',
    //       name: 'TextManage',
    //       component: () => import('@/views/ManagePush/TextManage/index.vue'),
    //       meta: {
    //         title: '文本消息',
    //         icon: 'remixicon-line',
    //       },
    //     },
    //     {
    //       path: 'AdvertisingImage',
    //       name: 'AdvertisingImage',
    //       component: () =>
    //         import('@/views/ManagePush/AdvertisingImage/index.vue'),
    //       meta: {
    //         title: '广告图',
    //         icon: 'remixicon-line',
    //       },
    //     },
    //   ],
    // },
    {
        path: '/rightsManagement',
        name: 'RightsManagement',
        component: Layout,
        meta: {
            title: '权限管理',
            icon: 'code-box-line',
        },
        children: [
            {
                path: 'userManagement',
                name: 'UserManagement',
                component: () => import('@/views/rightsManagement/userManagement/index.vue'),
                meta: {
                    title: '用户管理',
                    icon: 'remixicon-line',
                },
            },
            {
                path: 'adminManagement',
                name: 'AdminManagement',
                component: () => import('@/views/rightsManagement/adminManagement/index.vue'),
                meta: {
                    title: '管理员管理',
                    icon: 'remixicon-line',
                },
            },
            // {
            //   path: 'tenantUser',
            //   name: 'TenantUser',
            //   component: () =>
            //     import('@/views/rightsManagement/tenantUserManagement/index.vue'),
            //   meta: {
            //     title: '租户用户管理',
            //     icon: 'remixicon-line',
            //   },
            // },
            // {
            //   path: 'tenantUserAdd',
            //   name: 'TenantUserAdd',
            //   component: () =>
            //     import('@/views/tenantManagement/tenantAccounts/edit.vue'),
            //   meta: {
            //     title: '租户用户新增',
            //     activeMenu: '/rightsManagement/tenantUser',
            //     hidden: true,
            //     dynamicNewTab: true,
            //     // guard: ['TenantAccountsEdit'],
            //   },
            // },
            // {
            //   path: 'tenantUserEdit',
            //   name: 'TenantUserEdit',
            //   component: () =>
            //     import('@/views/tenantManagement/tenantAccounts/edit.vue'),
            //   meta: {
            //     title: '租户用户编辑',
            //     activeMenu: '/rightsManagement/tenantUser',
            //     hidden: true,
            //     dynamicNewTab: true,
            //     // guard: ['TenantAccountsEdit'],
            //   },
            // },
            {
                path: 'tenantRole',
                name: 'TenantRole',
                component: () => import('@/views/rightsManagement/tenantRole/index.vue'),
                meta: {
                    title: '租户角色',
                    icon: 'remixicon-line',
                    // guard: ['TenantRole'],
                },
            },
            {
                path: 'addTenantRole',
                name: 'AddTenantRole',
                component: () => import('@/views/rightsManagement/tenantRole/AddRole.vue'),
                meta: {
                    title: '新增租户角色',
                    activeMenu: '/rightsManagement/tenantRole',
                    dynamicNewTab: true,
                    hidden: true,
                    // guard: ['AddTenantRole'],
                },
            },
            {
                path: 'administratorRole',
                name: 'AdministratorRole',
                component: () => import('@/views/rightsManagement/administratorRole/index.vue'),
                meta: {
                    title: '管理员角色',
                    icon: 'remixicon-line',
                    // guard: ['AdministratorRole'],
                },
            },
            {
                path: 'addAdministratorRole',
                name: 'AddAdministratorRole',
                component: () => import('@/views/rightsManagement/administratorRole/AddRole.vue'),
                meta: {
                    title: '新增管理员角色',
                    activeMenu: '/rightsManagement/administratorRole',
                    dynamicNewTab: true,
                    hidden: true,
                    // guard: ['AddAdministratorRole'],
                },
            },
            {
                path: 'featureTenantRegister',
                name: 'FeatureTenantRegister',
                component: () => import('@/views/rightsManagement/featureTenantRegister/index.vue'),
                meta: {
                    title: '租户端功能注册',
                },
            },
            {
                path: 'featureAdminRegister',
                name: 'FeatureAdminRegister',
                component: () => import('@/views/rightsManagement/featureAdminRegister/index.vue'),
                meta: {
                    title: '运营端功能注册',
                },
            },
        ],
    },
    // {
    //   path: '/error',
    //   name: 'Error',
    //   component: Layout,
    //   meta: {
    //     title: '错误页',
    //     icon: 'error-warning-line',
    //     levelHidden: true,
    //   },
    //   children: [
    //     {
    //       path: '403',
    //       name: 'Error403',
    //       component: () => import('@/views/403.vue'),
    //       meta: {
    //         title: '403',
    //         icon: 'error-warning-line',
    //       },
    //     },
    //     {
    //       path: '404',
    //       name: 'Error404',
    //       component: () => import('@/views/404.vue'),
    //       meta: {
    //         title: '404',
    //         icon: 'error-warning-line',
    //       },
    //     },
    //   ],
    // },
    // {
    //   path: '/:pathMatch(.*)*',
    //   redirect: '/404',
    //   name: 'NotFound',
    //   meta: {
    //     hidden: true,
    //   },
    // },
];
