import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createBlock(_component_el_config_provider, {
    button: {
      autoInsertSpace: true
    },
    locale: $setup.locale
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view, null, {
      default: _withCtx(({
        Component
      }) => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 1 /* STABLE */
    }), $setup.pwa ? (_openBlock(), _createBlock($setup["VabUpdate"], {
      key: 0,
      ref: "vabUpdateRef"
    }, null, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]);
}