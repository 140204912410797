import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3dc3a550"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "vab-header"
};
const _hoisted_2 = {
  class: "vab-main"
};
const _hoisted_3 = {
  class: "right-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_logo = _resolveComponent("vab-logo");
  const _component_el_col = _resolveComponent("el-col");
  const _component_vab_menu = _resolveComponent("vab-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_vab_error_log = _resolveComponent("vab-error-log");
  const _component_vab_lock = _resolveComponent("vab-lock");
  const _component_vab_search = _resolveComponent("vab-search");
  const _component_vab_notice = _resolveComponent("vab-notice");
  const _component_vab_full_screen = _resolveComponent("vab-full-screen");
  const _component_vab_language = _resolveComponent("vab-language");
  const _component_vab_theme = _resolveComponent("vab-theme");
  const _component_vab_refresh = _resolveComponent("vab-refresh");
  const _component_vab_avatar = _resolveComponent("vab-avatar");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
    gutter: 20
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6
    }, {
      default: _withCtx(() => [_createVNode(_component_vab_logo)]),
      _: 1 /* STABLE */
    }), _createVNode(_component_el_col, {
      span: 18
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_3, ['horizontal' === $props.layout ? (_openBlock(), _createBlock(_component_el_menu, {
        key: 0,
        "active-text-color": $setup.variables['menu-color-active'],
        "background-color": $setup.variables['menu-background'],
        "default-active": $setup.activeMenu.data,
        "menu-trigger": "hover",
        mode: "horizontal",
        style: {
          "width": "100%"
        },
        "text-color": $setup.variables['menu-color']
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.routes.flatMap(route => route['meta'] && route['meta']['levelHidden'] && route['children'] ? [...route['children']] : route), (item, index) => {
          return _openBlock(), _createElementBlock(_Fragment, null, [item['meta'] && !item['meta']['hidden'] ? (_openBlock(), _createBlock(_component_vab_menu, {
            key: index + item['name'],
            item: item,
            layout: $props.layout
          }, null, 8 /* PROPS */, ["item", "layout"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
        }), 256 /* UNKEYED_FRAGMENT */))]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["active-text-color", "background-color", "default-active", "text-color"])) : _createCommentVNode("v-if", true), _createVNode(_component_vab_error_log), _createVNode(_component_vab_lock), _createVNode(_component_vab_search), _createVNode(_component_vab_notice), _createVNode(_component_vab_full_screen), _createVNode(_component_vab_language), _createVNode(_component_vab_theme), _createVNode(_component_vab_refresh), _createVNode(_component_vab_avatar)])]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  })])]);
}