import "core-js/modules/es.array.push.js";
export const useErrorLogStore = defineStore('errorLog', {
  state: () => ({
    errorLogs: []
  }),
  getters: {
    getErrorLogs: state => state.errorLogs
  },
  actions: {
    addErrorLog(errorLog) {
      this.errorLogs.push(errorLog);
    },
    clearErrorLog() {
      this.errorLogs.splice(0);
    }
  }
});