import { hasPermission } from '@/utils/permission';
export default {
    install(app) {
        /**
         * @description 自定义指令v-permissions
         */
        app.directive('permissions', {
            mounted(el, binding) {
                const { value } = binding;
                if (value)
                    if (!hasPermission(value))
                        el.parentNode && el.parentNode.removeChild(el);
            },
        });
    },
};
