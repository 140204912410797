/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { useAclStore } from './acl';
import { useTabsStore } from './tabs';
import { useRoutesStore } from './routes';
import { useSettingsStore } from './settings';
import { getUserInfoAPI, socialLogin } from '@/api/user';
import { getToken, removeToken, setToken } from '@/utils/token';
// import { getFormInfo } from '@/views/login/captcha'
import { resetRouter } from '@/router';
// import { isArray, isString } from '@/utils/validate'
import { tokenName } from '@/config';
import { gp } from '@gp';
// const script = document.createElement('script')
// script.type = 'text/javascript'
// script.src =
//   'https://o.alicdn.com/captcha-frontend/aliyunCaptcha/AliyunCaptcha.js'
// document.body.appendChild(script)
export const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    username: '',
    avatar: '',
    backRoutes: [],
    permission: []
  }),
  getters: {
    getToken: state => state.token,
    getUsername: state => state.username,
    getAvatar: state => state.avatar,
    getRoutes: state => state.backRoutes
  },
  actions: {
    /**
     * @description 设置token
     * @param {*} token
     */
    setToken(token) {
      this.token = token;
      setToken(token);
    },
    /**
     * @description 设置用户名
     * @param {*} username
     */
    setUsername(username) {
      this.username = username;
    },
    /**
     * @description 设置头像
     * @param {*} avatar
     */
    setAvatar(avatar) {
      this.avatar = avatar;
    },
    /**
     * @description 登录拦截放行时，设置虚拟角色
     */
    setVirtualRoles() {
      const aclStore = useAclStore();
      aclStore.setFull(true);
      this.setUsername('');
      this.setAvatar('');
    },
    setRoutes(backRoutes) {
      this.backRoutes = backRoutes;
    },
    //人员权限
    setPermission(permission) {
      this.permission = permission;
    },
    /**
     * @description 登录
     * @param {*} userInfo
     */
    // async login(userInfo: any) {
    //   const { result } = await login(userInfo)
    //   const { [tokenName]: token } = result
    //   this.afterLogin(token, tokenName)
    // },
    async login(userInfo) {
      // const {
      //   result: { [tokenName]: token },
      // } = await login(userInfo)
      // const {
      //   result: { [tokenName]: token },
      // } = await captchaVerifyCallback(userInfo)
      // console.log('登录')
      /*
        这一行被注释了
      */
      // getFormInfo(userInfo)
      //   if (to.path == 'login') {
      //     getFormInfo(userInfo)
      //   }
      // })
      // if (to.path('/login')) {
      //   getFormInfo(userInfo)
      // }
      // this.afterLogin(token, tokenName)
    },
    /**
     * @description 设置token并发送提醒
     * @param {string} token 更新令牌
     * @param {string} tokenName 令牌名称
     */
    afterLogin(token, tokenName) {
      const settingsStore = useSettingsStore();
      if (token) {
        this.setToken(token);
      } else {
        const err = `登录接口异常，未正确返回${tokenName}...`;
        gp.$baseMessage(err, 'error', 'vab-hey-message-error');
        throw err;
      }
    },
    /**
     * @description 第三方登录
     * @param {*} tokenData
     */
    async socialLogin(tokenData) {
      const {
        data: {
          [tokenName]: token
        }
      } = await socialLogin(tokenData);
      this.afterLogin(token, tokenName);
    },
    /**
     * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
     * @returns
     */
    async getUserInfo() {
      return new Promise(resolve => {
        getUserInfoAPI().then(res => {
          if (res.code === 200) {
            console.log('res', res);
            const {
              userInfo,
              routes,
              permissions
            } = res.result;
            this.setUsername(userInfo?.name);
            // // 如不使用avatar头像,可删除以下代码
            this.setAvatar(userInfo?.avatar);
            this.setRoutes(routes);
            this.setPermission(permissions);
            console.log('更新用户信息');
            resolve({
              userInfo,
              routes,
              permissions
            });
          }
        }).catch(err => {
          console.error(err);
        });
      });
      /**
       * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
       * username {String}
       * avatar {String}
       * roles {List}
       * ability {List}
       */
      // if (
      //   (username && !isString(username)) ||
      //   (avatar && !isString(avatar)) ||
      //   (roles && !isArray(roles)) ||
      //   (permissions && !isArray(permissions))
      // ) {
      //   const err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确'
      //   gp.$baseMessage(err, 'error', 'vab-hey-message-error')
      //   throw err
      // } else {
      //   const aclStore = useAclStore()
      //   // 如不使用username用户名,可删除以下代码
      //   if (username) this.setUsername(username)
      //   // 如不使用avatar头像,可删除以下代码
      //   if (avatar) this.setAvatar(avatar)
      //   // 如不使用roles权限控制,可删除以下代码
      //   if (roles) aclStore.setRole(roles)
      //   // 如不使用permissions权限控制,可删除以下代码
      //   if (permissions) aclStore.setPermission(permissions)
      // }
    },
    /**
     * @description 退出登录
     */
    async logout() {
      // await logout()
      await this.resetAll();
      // 解决横向布局退出登录显示不全的bug
      location.reload();
      // router.push('/login')
      window.location.reload();
    },
    /**
     * @description 重置token、roles、permission、router、tabsBar等
     */
    async resetAll() {
      this.setToken('');
      // this.setUsername('游客')
      // this.setAvatar('https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
      const aclStore = useAclStore();
      const routesStore = useRoutesStore();
      const tabsStore = useTabsStore();
      aclStore.setPermission([]);
      aclStore.setFull(false);
      aclStore.setRole([]);
      tabsStore.delAllVisitedRoutes();
      routesStore.clearRoutes();
      await resetRouter();
      removeToken();
    }
  }
});