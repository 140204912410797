import { useSettingsStore } from '@/store/modules/settings';

import { defineComponent, ref, watch, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useEventListener } from '@vueuse/core';
const imports = require.context('./', true, /\.vue$/);
const Components = {};
imports.keys().filter(key => key !== './index.vue').forEach(key => {
  Components[key.replace(/(\/|\.|index.vue)/g, '')] = imports(key).default;
});
export default defineComponent({
  name: 'Layouts',
  components: Components,
  setup() {
    const settingsStore = useSettingsStore();
    const {
      device,
      collapse,
      theme
    } = storeToRefs(settingsStore);
    const {
      toggleDevice,
      foldSideBar,
      openSideBar,
      updateTheme
    } = settingsStore;
    const mobile = ref(false);
    let oldLayout = theme.value.layout;
    const resizeBody = () => {
      mobile.value = document.body.getBoundingClientRect().width - 1 < 992;
    };
    watch(mobile, val => {
      if (val) {
        oldLayout = theme.value.layout;
        foldSideBar();
      } else openSideBar();
      theme.value.layout = val ? 'vertical' : oldLayout;
      toggleDevice(val ? 'mobile' : 'desktop');
    });
    resizeBody();
    updateTheme();
    const cleanup = useEventListener('resize', () => {
      resizeBody();
    });
    onUnmounted(() => {
      if (mobile.value) theme.value.layout = oldLayout;
      cleanup();
    });
    return {
      theme,
      device,
      mobile,
      collapse,
      foldSideBar,
      openSideBar,
      toggleDevice
    };
  }
});