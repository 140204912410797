import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-79b3ced0"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_logo = _resolveComponent("vab-logo");
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_vab_menu = _resolveComponent("vab-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createBlock(_component_el_scrollbar, {
    class: _normalizeClass(["vab-column-bar-container", {
      'is-collapse': $setup.collapse,
      ['vab-column-bar-container-' + $setup.theme.columnStyle]: true
    }])
  }, {
    default: _withCtx(() => [_createVNode(_component_vab_logo), _createVNode(_component_el_tabs, {
      modelValue: $setup.tab.data,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.tab.data = $event),
      "tab-position": "left",
      onTabClick: $setup.handleTabClick
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.routes, (item, index) => {
        return _openBlock(), _createBlock(_component_el_tab_pane, {
          key: index + item.name,
          name: item.name
        }, {
          label: _withCtx(() => [_createElementVNode("div", {
            class: _normalizeClass(["vab-column-grid", {
              ['vab-column-grid-' + $setup.theme.columnStyle]: true
            }]),
            title: $setup.translate(item.meta.title)
          }, [_createElementVNode("div", null, [item.meta.icon ? (_openBlock(), _createBlock(_component_vab_icon, {
            key: 0,
            icon: item.meta.icon,
            "is-custom-svg": item.meta.isCustomSvg
          }, null, 8 /* PROPS */, ["icon", "is-custom-svg"])) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString($setup.translate(item.meta.title)), 1 /* TEXT */)])], 10 /* CLASS, PROPS */, _hoisted_1)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_el_menu, {
      "background-color": $setup.variables['column-second-menu-background'],
      "default-active": $setup.activeMenu.data,
      "default-openeds": $setup.defaultOpeneds,
      mode: "vertical",
      "unique-opened": $setup.uniqueOpened
    }, {
      default: _withCtx(() => [_createVNode(_component_el_divider, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($setup.translate($setup.tabMenu ? $setup.tabMenu.meta.title : $setup.tabMenu)), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.partialRoutes, item => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: item.path
        }, [!item.meta.hidden ? (_openBlock(), _createBlock(_component_vab_menu, {
          key: 0,
          item: item
        }, null, 8 /* PROPS */, ["item"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["background-color", "default-active", "default-openeds", "unique-opened"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}