import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4b7794ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "logo"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["logo-container", {
      ['logo-container-' + $setup.theme.layout]: true
    }])
  }, [_createVNode(_component_router_link, {
    to: "/"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createCommentVNode(" 使用自定义svg示例 "), $setup.logo ? (_openBlock(), _createBlock(_component_vab_icon, {
      key: 0,
      icon: $setup.logo,
      "is-custom-svg": ""
    }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true)]), _createElementVNode("span", {
      class: _normalizeClass(["title", {
        'hidden-xs-only': $setup.theme.layout === 'horizontal'
      }])
    }, _toDisplayString($setup.title), 3 /* TEXT, CLASS */)]),
    _: 1 /* STABLE */
  })], 2 /* CLASS */);
}